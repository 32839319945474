<template>
  <div class="templage u layout_left_right">
    <div class="layout">
      <div class="layout_left">
        <ui_side_menu
          :data-activeIndex="activeIndex"
          :sideMenuList="sideMenuList"
          :activeIndex="activeIndex"
          @updateIndex="updateIndex"
        >
        </ui_side_menu>
      </div>
      <div class="layout_right" v-if="userinfo">
        <router-view></router-view>
      </div>
      <!-- <userLoginVue v-else /> -->
    </div>
  </div>
</template>
<script setup>
import { ref, computed, provide, onMounted } from "vue";
import ui_side_menu from "../components/ui/ui_side_menu.vue";
import { useStore } from "vuex";
import common from "../utils/common";
import { useRouter } from "vue-router";
import userLoginVue from "../components/userHub/userLogin.vue";
let isProd = common.isProduction;

let store = useStore();
let router = useRouter();
console.log("router:", router.currentRoute.value);
let lang = computed(() => {
  return store.state.lang;
});

let userinfo = computed(() => {
  console.warn("store.state.userInfo", store.state.userInfo);
  localStorage.setItem('token',store.state.userInfo.token)
  if (store.state.userInfo && store.state.userInfo.uid) {
    return store.state.userInfo;
  } else {
    common.showLogin();
    return null;
  }
});

let sideMenuList = computed(() => {
  if (lang.value == "en") {
    return ["My Wallet", "History", "Transfer"];
  } else {
    return ["我的錢包", "錢包記錄", "轉賬"];
  }
});
let activeIndex = computed(() => {
  if (router.currentRoute.value.query.activeindex) {
    return router.currentRoute.value.query.activeindex;
  } else {
    return 0;
  }
});
const updateIndex = (v) => {
  router.push({ path: "/u", query: { activeindex: v } });
};

const formatChainStatus = (s, type) => {
  switch (s) {
    case 0:
      if (type == 3) {
        return lang.value != "en" ? "等待用戶轉賬" : "Expecting Transfer";
      } else {
        return lang.value != "en" ? "進行中" : "Expecting Transfer";
      }
    case 1:
      return lang.value != "en" ? "已完成" : "Completed";
    case 2:
      return lang.value == "en" ? "Time Out" : "超時";
    case 3:
      return lang.value == "en" ? "Transferring" : "轉賬中";
    default:
      return "-";
  }
};
provide("formatChainStatus", formatChainStatus);

// 從系統配置中讀取手續費存入store
//拉取系統配置
common.getSysConfig(
  "asset_exchange_charge_rate_for_diamond_to,asset_exchange_charge_rate_for_diamond_from,asset_exchange_charge_rate,blockchain_withdraw_rate,blockchain_exchange_rate,bsc_withdraw_lower_bound,bwallet_charge_rate_info"
);

onMounted(() => {
  if (!userinfo.value) {
    common.showLogin();
    store.commit("userHubStore/set_showUserHub", true);
  }
});
</script>
<style lang="scss" scoped src="./u.scss"></style>
<style lang="scss">
.u.layout_left_right {
  .hr {
    height: 0;
    line-height: 0;
    font-size: 0;
    overflow: hidden;
    border-top: 1px solid #38366c;
    margin-top: 0.25rem;
  }

  .tips {
    padding: 0 0.6rem;
    font-size: 0.14rem;
    color: #8d8f9f;

    span {
      color: #ef4f55;
    }
  }
}
</style>
<style lang="scss" src="./u_mobile.scss"></style>
